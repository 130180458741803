import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { 
  Row, 
  Col, 
  Container 
} from "reactstrap"
import Img from "gatsby-image"
import { graphql } from "gatsby"

export default function AboutUs({data}){
  return(
    <Layout pageInfo={{ pageName: "aboutus" }}>
      <SEO title="Chi siamo?" />
      <Container className="mt-5">
        <Row>
          <Col md="6">
            <h1>Chi siamo?</h1>
            <p className="text-justify"><b>Autoriparazioni Pit Stop di Inselvini E. e Dalvai E. snc</b>, situata a Villa Carcina in via Toscana n°20,  nata nel 2005 come piccola officina, con il tempo ha ampliato i suoi ambiti di competenza, non limitandosi alla semplice riparazione di autoveicoli ma spaziando nei vari campi dell’automotive, eseguendo - ad esempio - diagnosi computerizzate, riparazioni di motori o di cambi automatici. 
I nostri servizi vengono eseguiti da meccanici specializzati con pluriennale esperienza, mettendo insieme la manualità artigianale alle più moderne tecnologie in campo elettronico, e variano dal cambio gomme alla riparazione del motore, passando per il soccorso stradale, grazie al quale saremo in grado di risolvere ogni vostra emergenza. 
Infine, ma decisamente da non sottovalutare, siamo in grado di fornirvi un servizio di elettrauto completo, occupandoci del sistema elettrico di un veicolo (batteria, motorino di avviamento, alternatore, fusibili, cavi e connettori), e di tutti i dispositivi presenti che funzionano grazie all'energia elettrica (sistema fari e luci, antifurto ecc...).
Abbiamo a disposizione un’ampia area attrezzata per qualsiasi tipo di manutenzione
Garantiamo un’assistenza confortevole, affidabile e all’avanguardia.
Per noi è importante che tu possa contare su un veicolo in perfette condizioni o, in caso di guasti, pronto a tornare a pieno regime velocemente. Perché la tua soddisfazione è la nostra più grande ambizione.</p>
          </Col>
          <Col>
            <Img fluid={data.file.childImageSharp.fluid} className="rounded shadow mt-5 ml-1"/>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativeDirectory: {eq: "aboutUs"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`